import React from 'react'
import './Cards.css'
const Cards = () => {
    const [current, setCurrent] = React.useState(0);
    const images = ["https://www.largus.fr/images/images/mercedes-amg-cls-53-4matic-11.jpg","https://images.caradisiac.com/images/6/2/7/1/186271/S0-essai-video-bmw-serie-5-restylee-2020-651752.jpg","https://www.automobile-magazine.fr/asset/cms/147068/config/100876/avec-la-suspension-pneumatique-et-les-quatre-roues-directrices-optionnelles-ce-touareg-troisieme-du-nom-se-joue-des-virages-avec-aisance.jpg"];
    const imageSlides = document.getElementsByClassName("slide-image");
    const bubbles = document.getElementsByClassName("bubble-outer");
    const nextImage = () => {
      let newIndex = (current === images.length - 1 ? 0 : current + 1);
      imageSlides[current].className = "slide-image leftOut";
      imageSlides[newIndex].className= "slide-image leftIn";
      updateBubbles(newIndex);
      setCurrent(newIndex);
    }
    const prevImage = () => {
      let newIndex = (current === 0 ? images.length - 1 : current - 1);
      imageSlides[current].className = "slide-image rightOut";
      imageSlides[newIndex].className= "slide-image rightIn";
      updateBubbles(newIndex);
      setCurrent(newIndex);
    }
    const jumpImage = (e) => {
      let jumpIndex = parseInt(e.target.id);
      if(jumpIndex === current) return;
      if(jumpIndex - current >= 0) {
        imageSlides[current].className = "slide-image leftOut";
        imageSlides[jumpIndex].className = "slide-image leftIn";
      } else {
        imageSlides[current].className = "slide-image rightOut";
        imageSlides[jumpIndex].className = "slide-image rightIn";
      }
      updateBubbles(jumpIndex);
      setCurrent(jumpIndex);
    }
    const updateBubbles = (highlight) => {
      bubbles[current].style.borderColor = "black";
      bubbles[highlight].style.borderColor = "white";
    }
    React.useEffect(() => {
      bubbles[current].style.borderColor="white";
    }, [bubbles,current]);


  const touchStartX = React.useRef(0);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDiff = touchEndX - touchStartX.current;

    if (touchDiff > 50) {
      prevImage();
    } else if (touchDiff < -50) {
      nextImage();
    }
  };

    return (
    <>
      <div className="gallery-container" 
       onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      >
        {/* <span className="button-prev" onClick={prevImage}>chevron_left</span>
        <span className="button-next" onClick={nextImage}>chevron_right</span> */}
        <div className="gallery-track">
          {
            images.map((image, index) => {
              return <div className="slide-image" style={ (index === 0) ? { backgroundImage: `url(${image})`, transform:"translateX(0%)" } : { backgroundImage: `url(${image})`, transform:"translateX(100%)" }}></div>
            })
          }
        </div>
       
      </div>
       <div >
       {
         images.map((image, index) => {
           return <div className="bubble-outer" onClick={jumpImage} id={index}>
             <div className="bubble-inner" id={index}>
             </div>
           </div>
         })
       }
     </div>
     </>
    )
  }
export default Cards