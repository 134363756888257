import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import wb from '../img/wb.png' 
function Home() {

    const auth = getAuth();
    
    const [user, loading] = useAuthState(auth);
    
    useEffect(() => {
      
    }, [user, loading]);

    return (
        <div className="mx-auto  max-w-md">
            <h1 className="text-3xl font-medium text-center p-5">Chauffeur Privé à votre service</h1>
            {!user && <><h1 className="text-lg flex justify-center p-5">WB pour tous vos déplacements</h1> 
            <h1 className="text-lg flex justify-center p-5">Transfert Aéroport</h1> 
            <h1 className="text-lg flex justify-center p-5">Longues Distances</h1> 
            <h1 className="text-lg flex justify-center p-5">Evénement</h1> 
            <h1 className="text-lg flex justify-center p-5">Soirées</h1> 


            </>  }
<img src={wb} alt="wb" />
        </div>
    );
}

export default Home;