import { useState, useEffect } from "react";
import "../../tailwind-imports.css";
import { db } from "../../firebase-config";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
import { ContactWrapper, Email } from "./ContactElements";
import wb  from '../../img/wb.png'
function Rooms() {
  // const navigate = useNavigate();

  //list of rooms
  const [rooms,setRooms] = useState([]);

  const [bookings, setBookings] = useState({});

  const [username, setUsername] = useState("");

  //get user
  const auth = getAuth();
  // const user = auth.currentUser;

  onAuthStateChanged(auth, (user) => {
    if (user || rooms || bookings || username)  {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setUsername(user.displayName);
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  useEffect(() => {
    //get collection of bookings
    const bookingsRef = collection(db, "bookings");

    //send the collection bookings to an array
    const getBookings = async () => {
      const data = await getDocs(bookingsRef);
      let bookingsArray = data.docs.map((doc) => ({
        roomID: doc.data().roomID,
        username: doc.data().username,
        bookedUntil: doc.data().bookUntil,
        bookingNote: doc.data().bookingNote,
      }));
      let bookingsDict = {};
      for (const booking of bookingsArray) {
        bookingsDict[booking.roomID] = {
          username: booking.username,
          bookedUntil: booking.bookedUntil,
          bookingNote: booking.bookingNote,
        };
      }
      setBookings(bookingsDict);
      console.log(bookingsDict);
    };
    getBookings();

    const roomsCollectionRef = collection(db, "rooms");
    //send the collection rooms to an array
    const getRooms = async () => {
      const data = await getDocs(roomsCollectionRef);
      setRooms(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getRooms();

    //log to console when the webpage updates for debugging, avoid infinite loops
    console.log("made a read");

    //update using a bookingstate helper called in the bookRoom function
  });

  return (
    <div className="Rooms mx-10 my-0">
      <ContactWrapper id="contact">
        <div className="Container">
          {/* <div className="SectionTitle">Get In Touch</div> */}
          <div className="BigCard">
            <Email>
              <span>wbvtc53@gmail.com</span>
              <a
                className="btn PrimaryBtn"
                href="mailto:wbvtc53@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Send Mail
              </a>
            </Email>
          </div>
          <br/>
          <br/>

          <div className="Container">
            <div className="BigCard">
              {" "}
              <Email>
                <span>07 64 55 16 85</span>
                {/* <span>Or</span> */}
                <a href="tel:0764551685" className="btn PrimaryBtn">
                  Call Now
                </a>
              </Email>{" "}
            </div>
          </div>
          <img src={wb} alt="wb" />
          {/* <SocialIcon /> */}
        </div>
      </ContactWrapper>
    </div>
  );
}

export default Rooms;
