import { getAuth } from "firebase/auth";
import Cards from './Cards/Card'
import Footer from "../../components/Footer";

function Bookings() {

    //get the user
    const auth = getAuth();
    const user = auth.currentUser;


    return (
        <div className="mx-auto">
            <h1 className="text-2xl flex justify-center font-bold p-5">Nos Voitures</h1>
            {!user ? <h1 className="text-lg flex justify-center p-5">Service de chauffeur privé haut de gamme offrant des trajets personnalisés et luxueux. Chauffeurs professionnels, discrets et attentifs pour des déplacements sécurisés. Profitez de notre flotte de véhicules haut de gamme équipés des dernières technologies. Réservez dès maintenant pour une expérience de voyage sur mesure, confortable et sans stress.</h1>: ""}
            {/* {myBookings.map((booking) => {
                return (
                    <div className='m-5 mx-auto shadow-md bg-base-200 rounded-md max-w-screen-md px-4 py-3 flex justify-between items-center gap-5'>
                        <h2 className="text-2xl">{rooms[booking.roomID]}</h2>
                        <h2 className="text-lg">Booked since: <ReactTimeAgo date={booking.timestamp} locale="nl-NL" timeStyle='round'/></h2>
                        <ConfirmDelete onClick={updateBookingStateHelper}
                            roomNumber={rooms[booking.roomID]}
                            roomID={booking.roomID}
                            bookingID={booking.id}
                            modalID={booking.id}
                            username={user.displayName}
                            statehelper={bookingStateHelper}
                        /> 
                    </div>
                );
                })
            } */}
   <Cards/>
   <Footer/>
        </div>
    );
}



export default Bookings;