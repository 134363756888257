import React from 'react';

const Footer = () => { 
  return (
    <footer className="bg-gray-800 py-8 sm:py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* <div className="text-white">
            <h3 className="text-xl font-bold mb-4">À propos de nous</h3>
            <p className="text-gray-400">Chauffeurs privés de confiance. Confort, fiabilité et service haut de gamme garantis.</p>
          </div> */}
          <div className="text-white">
            <h3 className="text-xl font-bold mb-4">Contact</h3>
            <p className="text-gray-400">Laval, Rennes</p>
            <p className="text-gray-400">wbvtc53@gmail.com</p>
            <p className="text-gray-400">0764551685</p>

          </div>
          <div className="text-white">
            <h3 className="text-xl font-bold mb-4">Liens utiles</h3>
            <ul>
              <li>
                <a href="/" className="text-gray-400 hover:text-white">Accueil</a>
              </li>
              <li>
                <a href="/pages/nos-voitures" className="text-gray-400 hover:text-white">Services</a>
              </li>
              <li>
                <a href="/pages/reservation" className="text-gray-400 hover:text-white">Réservation</a>
              </li>
            </ul>
          </div>
          <div className="text-white">
            <h3 className="text-xl font-bold mb-4">Réseaux sociaux</h3>
            <div className="flex space-x-4">
              {/* <a href="#" className="text-green-400 hover:text-white">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-green-400 hover:text-white">
                <i className="fab fa-twitter"></i>
              </a> */}
              <a href="https://www.instagram.com/" className="text-green-400 hover:text-white">
                <i className="fab fa-instagram">INSTAGRAM</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
